export const MuiTab = {
  defaultProps: {
    color: "blue",
  },
  styleOverrides: {
    root: {
      "&.Mui-selected": {
        color: "white",
      },
    },
  },
};
