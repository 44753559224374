export const palette = {
  mode: "dark",
  text: {
    primary: "#f5f5f5",
    main: "#f5f5f5",
    secondary: "rgba(245, 245, 245, 0.9)",
    disabled: "rgba(245, 245, 245, 0.5)",
  },
  divider: "rgba(255, 255, 255, 0.5)",
  background: { default: "#040410" },
  appBar: {
    main: "#12171c",
    contrastText: "#f2f2f2",
  },
  primary: {
    main: "hsl(42, 66%, 43%)",
    light: "hsl(47, 100%, 56%)",
    dark: "hsl(43, 58%, 39%)",
    contrastText: "#fff",
  },
  secondary: {
    light: "hsl(50, 62%, 71%)",
    main: "hsl(133, 30%, 47%)",
    dark: "hsl(47, 40%, 21%)",
    contrastText: "#fff",
  },
  kinetic: "#f5f5f5",
  arc: "#7aecf3",
  solar: "#f46d25",
  void: "#986eb4",
  stasis: "#406ad4",
  strand: "#38da65",
  grandmaster: "#623235",
  common: { main: "#356e41" },
  rare: { main: "#5176a4" },
  legendary: { main: "hsl(274, 33%, 29%)" },
  exotic: { main: "hsl(47, 60%, 50%)", dark: "hsl(43deg 58% 39%)" },
  heavyAmmo: { main: "hsl(265, 92%, 75%)" },
  specialAmmo: { main: "hsl(140, 87%, 67%)" },
  primaryAmmo: { main: "hsl(0, 0%, 90%)" },
};
